<template>
  <div class="main">
    <div class="banner">
      <img src="../../assets/image/banner_applet.png" alt>
      <div class="banner_tit">
        <h2>小程序</h2>
        <p>多业务场景、多行业商城小程序可按需实现适合你的专属小程序</p>
        <div class="btn">
          <a href>立即咨询</a>
        </div>
      </div>
    </div>
    <div class="feature">
      <h2>特色能力</h2>
      <div class="feature_list">
        <div>
          <img src="../../assets/image/community_icon1.png" alt>
          <p>体验</p>
        </div>
        <div>
          <img src="../../assets/image/community_icon1.png" alt>
          <p>流量</p>
        </div>
        <div>
          <img src="../../assets/image/community_icon1.png" alt>
          <p>智能</p>
        </div>
        <div>
          <img src="../../assets/image/community_icon1.png" alt>
          <p>开放</p>
        </div>
      </div>
    </div>
    <div class="scheme">
      <h2>行业解决方案</h2>
      <div class="scheme_list">
        <h2 @click='change(1)' :class='currentIndex==1?"on":""'>电商</h2>
        <h2 @click='change(2)' :class='currentIndex==2?"on":""'>内容</h2>
        <h2 @click='change(3)' :class='currentIndex==3?"on":""'>政务</h2>
        <h2 @click='change(4)' :class='currentIndex==4?"on":""'>品牌</h2>
      </div>

      <div class="btm" v-show="currentIndex==1">
        <div class="left">
          <h2>电商解决方案</h2>
          <p>电商行业市场竞争激烈，面对巨头鼎力现状针对流量获取难获客成本高等因素，赋能上架挖掘新流量洼地，提升成交转化率，优化售后服务促进回访为用户提供优质的购物体验。</p>
          <div class="btn">
            <a href>快速引流获客</a>
          </div>
        </div>
        <div class="right">
            <img src="../../assets/image/applet_case1.png" alt>
        </div>
      </div>
      <div class="btm" v-show="currentIndex==2">
        <div class="left">
          <h2>内容解决方案</h2>
          <p>基于内容行业特性与开发诉求提供行业组件能力，助理企业低沉本获取高价值流量用户，并实现流量变现</p>
          <div class="btn">
            <a href>快速引流获客</a>
          </div>
        </div>
        <div class="right">
            <img src="../../assets/image/applet_case2.png" alt>
        </div>
      </div>
      <div class="btm" v-show="currentIndex==3">
        <div class="left">
          <h2>政务解决方案</h2>
          <p>聚焦政务民生行业特性，助力政府机构实现政务信息发布和在线服务提升政务网上办事效率，让网上办公更加简单便捷</p>
          <div class="btn">
            <a href>快速引流获客</a>
          </div>
        </div>
        <div class="right">
            <img src="../../assets/image/applet_case3.png" alt>
        </div>
      </div>
      <div class="btm" v-show="currentIndex==4">
        <div class="left">
          <h2>电商解决方案</h2>
          <p>电商行业市场竞争激烈，面对巨头鼎力现状针对流量获取难获客成本高等困境，赋能上架挖掘新流量洼地，提升成交转化率，优化售后服务促进回访为用户提供优质的购物体验</p>
          <div class="btn">
            <a href>快速引流获客</a>
          </div>
        </div>
        <div class="right">
            <img src="../../assets/image/applet_case1.png" alt>
        </div>
      </div>
    </div>
    <div class="analyse">
      <h2>全面数据分析</h2>
      <img src="../../assets/image/applet_analyse.png" alt>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      currentIndex : 1
    }
    
  },
  methods:{
     change:function(index){
        this.currentIndex=index;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main .banner {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.banner img {
  width: 100%;
}
.banner .banner_tit {
  width: 600px;
  height: 300px;
  position: absolute;
  top: 130px;
  left: 385px;
  text-align: left;
}
.banner .banner_tit h2 {
  font-size: 60px;
  color: #fff;
}
.banner .banner_tit p {
  width: 580px;
  font-size: 24px;
  color: #fff;
  margin-top: 10px;
}
.banner .banner_tit .btn {
  width: 185px;
  height: 55px;
  background: #fff;
  border-radius: 2px;
  font-size: 17px;
  color: #ff0610;
  line-height: 55px;
  text-align: center;
  margin-top: 25px;
}
.feature {
  width: 1200px;
  margin: 70px auto 0;
}
.feature h2 {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
}
.feature .feature_list {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 70px;
}
.feature .feature_list div {
  width: 285px;
  height: 200px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #edeef0;
}
.feature .feature_list img {
  width: 100px;
  height: 100px;
  margin: 25px auto 0;
}
.feature .feature_list p {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-top: 15px;
}

.scheme {
  width: 100%;
  height: 692px;
  margin: 30px auto;
  background: #F0F4FC;
  padding-top: 30px;
}
.scheme h2 {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
}
.scheme .scheme_list {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-block-end: 38px;
  border-bottom: 1px solid #D6E4FF;
  padding: 0 40px;
}
.scheme .scheme_list .on{
font-size: 16px;
font-family: PingFangSC-Medium, PingFang SC;
color: #FF0610;
border-bottom: 3px solid #FF0610;
padding-bottom:17px;
}
.scheme .scheme_list h2 {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
}
.scheme .btm {
  width: 1200px;
  margin: 0 auto 30px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
}
.scheme .btm .left {
  width: 600px;
}
.scheme .btm .left h2 {
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  margin-top: 125px;
  text-align: left;
}
.scheme .btm .left p {
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  line-height: 22px;
  margin-top: 25px;
  text-align: left;
}
.scheme .btm .left .btn {
  width: 180px;
  height: 54px;
  background: #ff0610;
  border-radius: 2px;
  text-align: center;
  line-height: 54px;
  color: #fff;
  margin-top: 25px;
}
.scheme .btm .right{
    width: 208px;
    margin-right: 118px;
    padding-bottom: 25px;
}
.scheme .btm .right img{
    width: 100%;
    margin-top: 24px;
}
.analyse {
  width: 1200px;
  margin: 40px auto 70px;
}
.analyse h2 {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
}
.analyse img {
  width: 100%;
}
</style>
